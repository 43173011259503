import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Route, Switch, Redirect } from 'react-router-dom';

import fetch from 'cross-fetch';

import { Helmet } from 'react-helmet';
import Div100vh from 'react-div-100vh';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import themeOptions from '../themeOptions';

import { hydrateOrganization } from '../state/app';

import Loading from './Loading';
import Error from './Error';

const Location = lazy(() => import('./Location'));
const Finder = lazy(() => import('./Finder'));

// Script injector component to properly evaluate scripts
const ScriptInjector = ({ content }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!content || !containerRef.current) return;

    // Clear existing content
    containerRef.current.innerHTML = '';

    // Create a div with the content
    const div = document.createElement('div');
    div.innerHTML = content;

    // Find all scripts and execute them
    const scripts = div.querySelectorAll('script');
    scripts.forEach(script => {
      const newScript = document.createElement('script');

      // Copy all attributes
      Array.from(script.attributes).forEach(attr => {
        newScript.setAttribute(attr.name, attr.value);
      });

      // Copy content
      newScript.innerHTML = script.innerHTML;

      // Add to document to execute
      containerRef.current.appendChild(newScript);
    });

    // Add remaining non-script elements
    Array.from(div.childNodes).forEach(node => {
      if (node.nodeName !== 'SCRIPT') {
        containerRef.current.appendChild(node.cloneNode(true));
      }
    });
  }, [content]);

  return <div ref={containerRef} />;
};

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {
  componentDidMount() {
    const { hydrateOrganization } = this.props;

    // Get the window's host for our API call
    let { host: domain } = window.location;

    // Allow custom domain override via env variable
    if (process.env.REACT_APP_TEST_DOMAIN) {
      domain = process.env.REACT_APP_TEST_DOMAIN;
    }

    if (domain) {
      // Grab the data from the frontend endpoint
      fetch(`/api/organizations?domain=${domain}`)
        .then(
          response => response.json(),
          error => console.warn(error),
        )
        .then((data) => {
          // If there is an error
          if (data.error) {
            throw new Error(data.error);
          }

          // Make sure there is data
          if (data[0] && data[0].id) {
            // Hydrate the organization data
            hydrateOrganization(data[0]);
          }
        });
    }
  }

  render() {
    const { loading, organization, queryParams } = this.props;

    // Make and Provide a Material UI Theme
    const theme = createMuiTheme({
      ...themeOptions,
      palette: {
        primary: {
          main: organization.primary_color ? organization.primary_color : '#000000',
          contrastText: organization.primary_text_color ? organization.primary_text_color : '#fff',
        },
        secondary: {
          main: '#111111',
        },
      },
    });

    return (
      <Div100vh className="app-wrap">
        {loading ? (
          <Loading />
        ) : (
          <MuiThemeProvider theme={theme}>
            <Helmet>
              <title>{organization.name} {organization.product_string_single} Finder</title>
              <meta name="description" content={`Find ${organization.name} ${organization.product_string_plural} near you!`} />
            </Helmet>

            {/* Render header scripts with proper script execution */}
            {organization.header_scripts && (
              <ScriptInjector content={organization.header_scripts} />
            )}

            <Suspense fallback={<Loading />}>
              <Switch>
                <Route exact path="/" render={() => <Location />} />
                <Route exact path="/:lat,:lng/" render={match => <Finder geo={match.match.params} />} />
                <Route exact path="//" strict render={() => <Redirect to={`/${queryParams}`} />} />
                <Route render={() => <Error />} />
              </Switch>
            </Suspense>
            {/* Render footer scripts with proper script execution */}
            {organization.footer_scripts && (
              <ScriptInjector content={organization.footer_scripts} />
            )}
          </MuiThemeProvider>
        )}
      </Div100vh>
    );
  }
}

const mapStateToProps = ({ router, app }) => ({
  loading: app.isLoading,
  organization: app.organization,
  queryParams: router.location.search,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  hydrateOrganization,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
